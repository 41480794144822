import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css';
import Top from './Components/Top';
import "@fontsource/source-sans-pro/300.css"; // Defaults to weight 400.

function App() {
  return (
    <div class='mtext_font'>
      <Top />
    </div>
  );
}

export default App;
