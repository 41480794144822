import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import img1 from '../img/m-wrg-standard.webp';
import PDF1 from '../PDF/Tipuri-de-montaj.pdf';
import PDF2 from '../PDF/Exemple_de_aplicatii.pdf';
import PDF3 from '../PDF/Optiuni-de-control.pdf';
import PDF4 from '../PDF/Grile-pentru-fatada.pdf';
import PDF5 from '../PDF/Filtre.pdf';
import PDF6 from '../PDF/Fisa_tehnica_wrg.pdf';
import PDF7 from '../PDF/Brosura-integrala.pdf';

class Mwrgstandart extends Component {
    render() {
        return (
            <Container fluid >
                <div id='modele' class='row mbg2 justify-content-md-center ptop2 '>
                    <div class=' col-xl-1 col-md-11 col-lg-1'>
                    </div>
                    <div class='col-lg-6 col-xl-5 col-sm-11  col-md-11 text-center px-3 m-auto '>
                        <p class='mh1 text-center'>
                            <b>M-WRG Standard</b>
                        </p>
                        <p class='mh3'>
                            Sistemele de ventilație cu recuperare de căldură Meltem M-WRG se bazează în totalitate pe un
                            concept descentralizat. Ventilarea casei se realizează prin instalarea unei unități în fiecare
                            cameră.
                        </p>
                        <img
                            src={img1}
                            width={600}
                            height={384}
                            alt="m-wrg-device"
                            class='img-fluid'
                        />
                    </div>
                    <div class='col-xl-1 d-none d-xl-block '>
                    </div>
                    <div class='col-lg-4 col-xl-4 col-sm-9 text-center col-md-9 m-auto pt-3 px-5'>
                        <div className="d-grid gap-3">
                            <Button href={PDF1} target="_blank" variant="secondary" size="lg">
                                TIPURI DE MONTAJ
                            </Button>
                            <Button href={PDF2} target="_blank" variant="secondary" size="lg">
                                EXEMPLE DE APLICAȚII
                            </Button>
                            <Button href={PDF3} target="_blank" variant="secondary" size="lg">
                                OPȚIUNI DE CONTROL
                            </Button>
                            <Button href={PDF4} target="_blank" variant="secondary" size="lg">
                                GRILE PENTRU FAȚADĂ
                            </Button>
                            <Button href={PDF5} target="_blank" variant="secondary" size="lg">
                                FILTRE
                            </Button>
                            <Button href={PDF6} target="_blank" variant="secondary" size="lg">
                                FIȘA TEHNICĂ
                            </Button>
                            <Button href={PDF7} target="_blank" variant="dark" size="lg">
                                DESCARCĂ BROȘURA INTEGRALĂ
                            </Button>
                        </div>
                    </div>
                    <div class=' col-xl-1 col-md-11 col-lg-1'>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Mwrgstandart;
