import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import MapGoogle from './MapGoogle';

class Contacte extends Component {
    render() {
        return (
            <Container fluid>
                <div class='row mbg2 ptop2 text-center justify-content-center'>
                    <p class='mh1'>CONTACTE:</p></div>
                <div id='contacte' class='row mbg2 py-3'>
                    <div class='col-xl-1 col-lg-1 col-md-11 '></div>
                    <div class='col-lg-5 col-sm-8 col-md-8 px-3  m-auto'>
                        <div class='row border border-dark pt-3'>
                            <h4 class='px-3'>SC "Geotermal-AV" SRL</h4>
                            <h5 class='px-3'>telefon: (+373) 799 39 333</h5>
                            <h5 class='px-3'><a href='mailto:info@meltem.md'>e-mail: info@meltem.md</a></h5>
                            <h5 class='px-3'>Adresa: mun. Chisinau, str.Vasile Lupu 87/4</h5>
                        </div>
                    </div>
                    <div class='col-lg-5 col-md-8 col-sm-8 pt-3 m-auto'>
                        <MapGoogle />
                    </div>
                    <div class='col-xl-1 col-lg-1 col-md-11 '></div>
                </div>

            </Container>
        );
    }
}

export default Contacte;