import React, { Component } from 'react';
import Contacte from '../Components/Contacte';
import Home1 from '../Components/Home1';
import Home2 from '../Components/Home2';
import Home3 from '../Components/Home3';
import Line from '../Components/Line';
import Mwrg2 from '../Components/Mwrg2';
import Mwrgstandart from '../Components/Mwrgstandart';
import Vario from '../Components/Vario';
import Bottom from '../Components/Bottom';

class Home extends Component {
    render() {
        return (
            <>
                <Home1 />
                <div class='span mbg2'>
                <Home2 />
                <Home3 />
                <Line />
                <Mwrgstandart />
                <Line />
                <Mwrg2 />
                <Line />
                <Vario />
                <Line />
                <Contacte />
                <Bottom />
                </div>
            </>
        );
    }
}

export default Home;