import React, { Component } from 'react';
import img1 from '../img/m-umiditate.webp';
import img4 from '../img/m-economy.webp';
import img3 from '../img/m-sanatate.webp';
import img2 from '../img/m-zgomot.webp';

class Form1 extends Component {
    render() {
        return (
            <>
                <div class='row m-auto'>
                    <div class='col-6 m-auto'>
                        <img
                            src={img1}
                            width={70}
                            height={55}
                            class="img-fluid mhim"
                            alt="protectie umiditate"
                        />
                        <p class='mha21'><b>PROTECȚIE UMIDITATE</b></p>
                        <p class='mha31'>Protejează împotriva daunelor provocate de umezeală</p>
                        <p class='mha31'>Previne apariția mucegaiului</p>
                        <p class='mha31'>Securizează structura locuinței</p>
                    </div>
                    <div class='col-6 m-auto'>
                        <img
                            src={img3}
                            width={70}
                            height={55}
                            class="img-fluid mhim"
                            alt="stil sanatatos"
                        />
                        <p class='mha21'><b>STIL DE VIAȚĂ SĂNĂTOS</b></p>
                        <p class='mha31'>Bunăstare și confort prin aerul proaspăt preîncălzit</p>
                        <p class='mha31'>Mediu hipoalergenic asigurat prin filtrele de aer de înaltă performanță</p>
                        <p class='mha31'>Fără curenți de aer</p>
                    </div>
                </div>
                <div class='row m-auto'>
                    <div class='col-6 -auto'>
                        <img
                            src={img2}
                            width={70}
                            height={55}
                            class="img-fluid mhim"
                            alt="lipsa zgomot"
                        />
                        <p class='mha21'><b>FĂRĂ ZGOMOT EXTERIOR</b></p>
                        <p class='mha31'>Nivel ridicat de izolare fonică</p>
                        <p class='mha31'>Funcționare silențioasă</p>
                        <p class='mha31'>Ideal pentru dormitoare</p>
                    </div>
                    <div class='col-6 m-auto'>
                        <img
                            src={img4}
                            width={70}
                            height={55}
                            class="img-fluid mhim"
                            alt="economie"
                        />
                        <p class='mha21'><b>ECONOMIE DE ENERGIE</b></p>
                        <p class='mha31'>Reduce costurile de încălzire și emisiile de CO2</p>
                        <p class='mha31'>Consumul de energie redus</p>
                        <p class='mha31'>Locuință eficientă energetic (low-cost)s</p>
                    </div>
                </div>
            </>
        );
    }
}

export default Form1;