import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img1 from '../img/relax-home1.webp'
import img2 from '../img/green.webp'

class Home1 extends Component {
    render() {
        return (
            <Container fluid>
              
                <div id='1' class='row justify-content-md-center pmt-5  '>
                    <div class='col-lg-5 col-xl-4 col-sm-10 col-md-11 '>
                        <p class='mh1 text-start'>Sisteme de ventilație pentru confortul tău</p>
                        <p class='mh2 pt-3'>Bucură-te de aer curat și liniște în interiorul casei cu ajutorul sistemelor de ventilație descentralizate cu recuperare de căldură.</p>
                    </div>
                    <div class='col-lg-6 col-xl-6  col-md-none text-center d-none d-lg-block '>
                        <img
                            src={img1}
                            class="par1 img-fluid"
                            alt="comfort"
                        />
                    </div>
                </div>
                <div class='row '>
                <div class=' mh11 col-md-none text-center d-none d-lg-block '>
                </div>
                </div>
                <div class='d-lg-none d-xl-none d-xl-block text-center '>
                    <img
                        src={img1}
                        height={400}
                        width={600}
                        class="img-fluid"
                        // class="par1"
                        alt="comfort"
                    />
                </div>
                <div class='row justify-content-md-center mbg2'>
                    <div class='col-xl-10 col-lg-11 col-sm-11 col-md-11  m-auto text-center'>
                        <p class='mh2 pt-3 text-center'>
                            <img
                                src={img2}
                                height={40}
                                width={40}
                                alt="green"
                                class='mx-3'
                            />
                            <b>Salvam energia și sa beneficiem de aer proastăt</b></p>
                        <p class='mh3 pt-3 '>
                            Meltem este un brand German, producator de sisteme de ventilație descentralizată pentru
                            locuințe, spații de birouri, institutii medicale etc.
                        </p>
                        <p class='mh3'>
                            Acest dispozitiv funcționează pentru bunăstarea și sănătatea dumneavoastră. Aer proaspăt
                            și filtrat, cu pierderi minime de căldură este asigurat automat  în locuință. Aerul viciat
                            este evacuat, iar căldura este transferată (fără a intra în contact) aerului curat introdus
                            în încăpere.
                        </p>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Home1;