import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../Pages/Home';
import logo from '../img/Meltem_Moldova_Logo.webp'

function BasicExample() {
    return (
        <Router>
            <Navbar collapseOnSelect expand="md" className="fixed-top mbg border border-bottom text-center ">
                <Container >
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            width="120"
                            height="45"
                            className="d-inline-block align-center"
                            alt="Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto mlink m-auto">
                            <Nav.Link eventKey='1' class="m-auto px-4 my-3" href="/#1">Home</Nav.Link>
                            <Nav.Link eventKey='2' class="m-auto px-4 my-3" href="/#modele">Modele</Nav.Link>
                            <Nav.Link eventKey='3' class="m-auto px-4 my-3" href="/#contacte">Contacte</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <div className='row mlinkcont'>
                    <div class='d-none d-md-block col-md-12 '>
                        <h5>(+373) 799 39 333</h5>
                        <h5><a href="mailto:info@ochsner.md">info@meltem.md</a></h5>
                    </div>
                    </div>
                </Container>
            </Navbar>
            <Routes>
                <Route path='/' element={<Home />} />
            </Routes>
        </Router>

    );
}

export default BasicExample;