import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img1 from '../img/tuv.webp'
import img2 from '../img/air1.webp'
import img3 from '../img/air2.webp'

class Home2 extends Component {
    render() {
        return (
            <Container fluid>
                <div class='row mbg2 justify-content-md-center ptop'>
                    <div class=' col-xl-1 col-md-11  col-lg-1'>
                    </div>
                    <div class='col-lg-5 col-xl-5 col-sm-11  col-md-11 justify-content-md-center m-auto px-3'>
                        <p class='mh2 text-center'>
                            <b>Puteți respira – Vă aducem aer proaspăt</b>
                        </p>
                        <p class='mh3 '>
                            Aer proaspăt este foarte important pentru oameni să se simtă confortabil în încăperi închise,
                            indiferent dacă este o casa, apartament sau birou. Aceasta este o condiţie necesară pentru
                            sănătate şi bunăstare. Menţinerea ventilaţiei optime și confortabile în încăperi asigură un
                            climat sănătos în interior. Pe lângă creşterea nivelului de confort, sisteme de ventilatie
                            Meltem monitorizeaza umiditatea, ajutând astfel la salvarea valoriei proprietăţii.
                        </p>
                    </div>
                    <div class='col-lg-5 col-xl-5  col-sm-10 col-md-8 justify-content-md-center m-auto'>
                        <img
                            src={img2}
                            width={752}
                            height={238}
                            alt="air1"
                            class="img-fluid"
                        />
                        <img
                            src={img3}
                            width={752}
                            height={238}
                            alt="air2"
                            class="img-fluid pt-3"
                        />
                    </div>
                    <div class=' col-xl-1 col-md-11 col-lg-1'>
                    </div>
                </div>
                <div class='row mbg2 justify-content-md-center ptop flex-column-reverse flex-lg-row'>
                    <div class=' col-xl-1 col-md-11  col-lg-1'>
                    </div>
                    <div class='col-lg-5 col-xl-5  col-sm-11 col-md-10 justify-content-md-center m-auto'>
                        <img
                            src={img1}
                            width={1812}
                            height={571}
                            alt="tuv"
                            class="img-fluid"
                        />
                    </div>
                    <div class='col-lg-5 col-xl-5 col-sm-11 col-md-11 justify-content-md-center m-auto px-2'>
                        <p class='mh2 text-center'><b>Nivel optim de performanță certificat de Institutul German de Case Pasive</b></p>
                        <p class='mh3 '>
                            Produsele Meltem se ridică la cele mai înalte standarde calitative și tehnice.
                            Fiabilitatea, longevitatea și randamentul energetic au garanția testelor și
                            certificărilor (TÜV) Institutului German pentru Inginerie Civilă (DIBT).
                        </p>
                    </div>
                    <div class='col-lg-1 col-xl-1 col-md-11'>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Home2;