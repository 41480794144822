import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img1 from '../img/line.webp';

class Line extends Component {
    render() {
        return (
            <Container fluid>
                <div class='row mbg2 p-5'>
                    <img
                        src={img1}
                        width={1534}
                        height={80}
                        alt="line"
                        class='img-fluid'
                    />
                </div>
            </Container>
        );
    }
}

export default Line;