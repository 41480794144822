import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class Bottom extends Component {
    render() {
        return (
            <Container fluid>
                <div class='row text-center mbgb p-3'>
                    <p>SC "Geotermal-AV" SRL</p>
                </div>
            </Container>
        );
    }
}

export default Bottom;