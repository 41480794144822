import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import img1 from '../img/vario.webp';
import PDF1 from '../PDF/Vario-Brosura.pdf';

class Vario extends Component {
    render() {
        return (
            <Container fluid>
                <div class='row mbg2 justify-content-md-center ' >
                    <div class=' col-xl-1 col-md-11 col-lg-1'>
                    </div>
                    <div class='col-lg-6 col-xl-5 col-sm-11  col-md-11 text-center px-3 m-auto '>

                        <img
                            src={img1}
                            width={600}
                            height={384}
                            alt="vario-device"
                            class='img-fluid'
                        />
                    </div>
                    <div class='col-xl-1 d-none d-xl-block '>
                    </div>
                    <div class='col-lg-4 col-xl-4 col-sm-9 text-center col-md-9 m-auto px-5'>
                        <p class='mh1 text-center'>
                            <b>VARIO II – Ventilație pentru băi și toalete</b>
                        </p>
                        <p class='mh3'>
                            Meltem Vario II este ideal pentru băi și toalete, eliminând mirosurile
                            neplăcute și oferind protecție împotriva mucegaiului. Dispozitivele Vario II
                            sunt ușor de asamblat și de întreținut și oferă un randament inegalabil.
                        </p>
                        <div className="d-grid gap-3">
                            <Button href={PDF1} target="_blank" variant="dark" size="lg">
                                DESCARCĂ BROȘURA INTEGRALĂ
                            </Button>
                        </div>
                    </div>
                    <div class=' col-xl-1 col-md-11 col-lg-1'>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Vario;