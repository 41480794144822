import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import img1 from '../img/girl_sleeping.webp';
import img2 from '../img/product1.webp';
import Form1 from '../Components/Form1';

class Home3 extends Component {
    render() {
        return (
            <Container fluid>
                <div class='row mbg2 justify-content-md-center ptop'>
                    <div class=' col-xl-1 col-md-11  col-lg-1'>
                    </div>
                    <div class='col-lg-5 col-xl-6 col-sm-11  col-md-11 justify-content-md-center m-auto px-3'>
                        <p class='mh2 text-center'>
                            <b>Recuperarea căldurii protejează locuința și mediul înconjurător</b>
                        </p>
                        <p class='mh3'>
                            Ventilația individuală a camerei asigură confort, reducerea zgomotului,
                            îndepărtarea aerului învechit și creează un mediu hipoalergenic.
                        </p>
                    </div>
                    <div class='col-lg-5 col-xl-4 col-sm-10 col-md-8 justify-content-md-center m-auto'>
                        <img
                            src={img1}
                            width={752}
                            height={238}
                            alt="girl sleeping"
                            class="img-fluid opacity-75"
                        />
                    </div>
                    <div class='col-lg-1 col-xl-1 col-md-11'>
                    </div>
                </div>


                <div class='row mbg2 justify-content-md-center pt-3'>
                    <div class=' col-xl-1 col-md-11  col-lg-1'>
                    </div>
                    <div class='col-lg-10 col-xl-10 col-sm-11  col-md-11 justify-content-md-center m-auto px-3'>
                        <Form1 />
                    </div>
                    <div class=' col-xl-1 col-md-11  col-lg-1'>
                    </div>
                </div>
                
                <div class='row mbg2 justify-content-md-center pt-5'>
                    <div class=' col-xl-1 col-md-11 col-lg-1'>
                    </div>
                    <div class='col-lg-6 col-xl-5 col-sm-7  col-md-7 px-3 mx-auto'>
                        <p class='mh2 text-center'>
                            <b>Transformă-ți casa în oaza ta personală de bine.</b>
                        </p>
                        <p class='mh3'>
                            Ne simțim cel mai bine în casa noastră atunci când avem parte de aer proaspăt.
                            Concentrațiile crescute de CO2 pot duce la oboseală, dureri de cap, probleme
                            de concentrare și scăderea performanței. Ventilația corectă este astfel esențială pentru un mediu de locuit sănătos.
                        </p>
                        <p class='mh2 text-center'>
                            <b>Sisteme de ventilație descentralizate cu recuperare de căldură – WRG</b>
                        </p>
                        <p class='mh3'>
                            Sintagma recuperare de căldură (WRG), se referă la diversitatea de
                            întrebuințări a energiei termice. Meltem vă propune două soluții de tip
                            WRG pentru sisteme descentralizate cu recuperare de căldură și o soluție
                            dedicată pentru spațiile sanitare (VARIO II).
                        </p>
                    </div>
                    <div class='col-lg-5 col-xl-5 col-sm-4 text-center col-md-4 m-auto'>
                        <img
                            src={img2}
                            width={351}
                            height={460}
                            alt="m-wrg2-device"
                        class='img-fluid'
                        />
                    </div>
                    <div class=' col-xl-1 col-md-11 col-lg-1'>
                    </div>
                </div>



            </Container>
        );
    }
}

export default Home3;